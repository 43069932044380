<template>
  <!--
    The view for the SkimovieProblems-component.
    Used to display problems of skimovie-installations.
  -->
  <Portlet
    :title="$t('skimovieView.skimovie')"
    icon="video-slash"
    class="skimovie"
  >
    <SkimovieProblems />
  </Portlet>
</template>

<script>
export default {
  name: "Skimovie",
  components: {
    SkimovieProblems: () => import('@/components/Problem/SkimovieProblems.vue')
  },
  metaInfo () {
    return {
      title: this.$t('skimovieProblems')
    }
  }
}
</script>